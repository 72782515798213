<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "caiKuang",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                            '#地图快捷键为M键',
                            '#小地图必须展示(图2)',
                        ],
                        img:[
                            "com/init.png",
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            '#游戏在地图上找出矿石点(图1图2)',
                        ],
                        img:[
                            "3.png",
                            "4.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到地图采集页",
                            "#按图1说明参数:",
                            "1.刚刚记录的两个矿石的坐标,分别填写到坐标一坐标二",
                        ],
                        img:[
                            "2.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            '#可以把马哨,鹿,娇子,御风放到快捷栏上,移动时候自动使用',
                            '#确保各个点之间可以自动寻路,开始任务,支持6个坐标循环采集',
                        ],
                        img:[
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
